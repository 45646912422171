/**
 * for SP
 */
@media (max-width: 767px) {
  .l-main-container {
    padding-bottom: 32px;
  }
  .l-main-container + .l-main-container {
    padding-top: 32px;
  }
  .l-container {
    width: 100%;
    margin: 0 auto;
  }
  .l-section {
    h2 {
      font-size: 22px;
      > img {
        width: 100%;
      }
    }
    & + .l-section {
      margin-top: 30px;
    }
    > .l-section-head {
      padding: 8px 0;
    }
    .l-section-item {
      padding: 0 3.125%;
      > h3 {
        width: 100%;
        font-size: 20px;
        margin-bottom: 24px;
      }
      > p {
        text-align: left;
      }
      > h3 ~ p {
        margin-top: 0;
      }
    }
    .l-section-item + .l-section-item {
      margin: 48px 0;
    }
    &.l-section-2column {
      .l-section-item {
        width: 100%;
        float: none;
        box-sizing: border-box;
      }
      .l-section-item + .l-section-item {
        margin-left: 0;
      }
    }
    > .l-section-head {
      margin-bottom: 30px;
    }
    .c-desc {
      > .c-desc-text {
        width: 100%;
        > h3 {
          margin: 20px 0 20px 0;
          text-align: center;
          font-size: 18px;
        }
      }
      > .c-desc-figure-m {
        width: 100%;
        > img {
          width: 100%;
        }
      }
      > .c-desc-figure-l {
        width: 100%;
        > img {
          width: 100%;
        }
      }
      > .c-desc-figure + .c-desc-text {
        margin-top: 24px;
      }
    }
    .c-desc-standalone {
      > .c-desc-text {
        > p {
          font-size: 20px;
          line-height: 1.8;
          text-align: left;
        }
      }
      margin-bottom: 50px;
    }
    .c-desc-l {
      > .c-desc-figure {
        float: none;
        margin: 0 auto;
      }
      > .c-desc-figure-s + .c-desc-text,
      > .c-desc-figure-m + .c-desc-text,
      > .c-desc-figure-l + .c-desc-text {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .c-desc-r {
      > .c-desc-figure {
        float: none;
      }
      > .c-desc-figure-s + .c-desc-text,
      > .c-desc-figure-m + .c-desc-text,
      > .c-desc-figure-l + .c-desc-text {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .c-brand-list {
      > dd {
        display: block;
        float: left;
        margin: 0 0 16px 0;
      }
      &.c-brand-list-1column > dd {
        width: 100%;
      }
      &.c-brand-list-2column > dd {
        width: 49%;
        margin-bottom: 12px;
        &:nth-child(odd) {
          margin-left: 2%;
        }
      }
      &.c-brand-list-3column > dd {
        width: 49%;
        margin-bottom: 12px;
        &:nth-child(odd) {
          margin-left: 0;
        }
        &:nth-child(even) {
          margin-left: 2%;
        }
      }
      > dd > a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
      > dd > a > span {
        display: block;
        position: initial;
        width: 100%;
        box-sizing: border-box;
        padding: 6px 10px;
        bottom: 0;
        background-color: #000;
        font-size: 14px;
        &:before {
          display: inline-block;
          content: ' ';
          width: 14px;
          height: 11px;
          background-image: url("../images/components/c-nav-list_arrow_w.png");
          background-size: 6px 11px;
          background-repeat: no-repeat;
        }
      }

      &.c-brand-list-osaiya > dd,
      &.c-brand-list-kitashinchi > dd,
      &.c-brand-list-onlinestore > dd,
      &.c-brand-list-omiyage > dd {
        height: auto;
        background-repeat: no-repeat;
        background-size: 100%;
      }
      &.c-brand-list-bettei > dd {
        height: auto;
      }
    }
    .c-news-list {
      > li {
        float: none;
        width: 100%;
        margin: 0 0 0 2%;
        max-height: 230px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      > li:nth-child(3n+1), li + li {
        margin-left: 0;
      }
      > li+ li {
        padding-top: 10px;
        border-top: solid 1px #fff;
      }
      .c-news-thumbnail {
        width: 24%;
        margin-bottom: 10px;
        float: left;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .c-news-info, .c-news-summary {
        float: right;
        width: 74%;
        padding: 0;
      }
    }
  }
  .l-footer {
    padding: 32px 0 24px 0;
    margin-top: 0;
    .l-footer-list {
      > li {
        display: block;
        box-sizing: border-box;
        padding: 0 4%;
        border-right: none;
        text-align:left;
      }
      > li + li {
        padding: 0 4%;
        border-top: solid 1px #555;
      }
      > li a {
        display: block;
        width: 100%;
        height: 48px;
        line-height: 48px;
        &::after {

        }
      }
    }
    .l-footer-list + .l-footer-list {
      border-top: solid 1px #555;
      margin-top: 0;
    }
    .l-copyright {
      margin-top: 24px;
    }
  }
  .c-breadcrumb {
    box-sizing: border-box;
    padding: 0 3.125%;
    margin-top: 24px;
  }
  .c-carousel > .swiper-container {
    height: 600px;
  }
  .c-carousel .c-carousel-title {
    font-size: 17px;
  }
  .c-carousel .c-carousel-text {
    font-size: 13px;
  }

  .l-section .c-shop-menu-list {
    > li {
      margin-bottom: 8px;
    }
    &.c-shop-menu-list-3column li {
      width: 49%;
      margin-left: 0;
      &:nth-child(even) {
        margin-left: 2%;
      }
    }
    &.c-shop-menu-list-4column li {
      width: 49%;
      margin-left: 0;
      &:nth-child(even) {
        margin-left: 2%;
      }
    }
    li > a > span {
      position: relative;
      padding: 8px 10px;
      background-color: rgba(0, 0, 0, 1);
    }
  }

  .c-accessmap {
    width: 100%;
    height: 360px;
  }
  .l-header .c-logo {
    margin-left: 16px;
    > img {
      width: auto;
      height: 38px;
    }
  }
  .l-header .c-nav-list.c-nav-list-shop {
    display: none;
  }
  .l-header .c-nav-list.c-nav-list-menu {
    display: block;
    margin: 0;
    padding: 0 0 0 8px;
    > li {
      position: relative;
      padding: 0 20px;
      background-image: url('../images/common/img_sp_nav_menu.png');
      background-repeat: no-repeat;
      background-position: 50% 20px;
      background-size: 32px auto;
      text-align: center;
      &::before {
        display: none;
      }
      > a {
        font-size: 10px;
      }
      > a:hover {
        color: #000;
        text-decoration: none;
      }
      > a span {
        position: relative;
        top: 12px;
      }
    }
  }
  .l-header .c-nav-list.c-nav-list-shop {
    display: none;
    position: fixed;
    top: 72px;
    right: 0;
    width: 100%;
    padding-left: 0;
    background-color: rgba(255,255,255, .8);
    text-align: left;
    > li {
      display: block;
      width: 100%;
      line-height: 64px;
      padding: 0 24px;
      > a {
        width: 90%;
        height: 64px;
        line-height: 64px;
      }
    }
    > li + li {
      border-top: solid 1px #fff;
      margin-left: 0;
    }
  }

  .c-course-price-list {
    width: 100%;
    > dt {
      font-size: 16px;
      width: 60%;
    }
    > dt + dd {
      padding-bottom: 8px;
      width: 40%;
      font-size: 16px;
    }
  }
  h3 + .c-course-price-list {
    margin-top: 0;
    margin-bottom: 24px;
  }
  .c-food-list {
    > li {
      padding-right: 132px;
    }
  }
  .c-food-list.c-food-list-course {
    > li {
      padding-right: 0;
    }
  }
  .c-drink-list {
    > li {
      padding-right: 72px;
      > .c-drink-item-price {
        width: 33%;
        text-align: right;
      }
    }
  }

  .l-container-subnav > .c-breadcrumb {
    width: 100%;
    float: none;
  }
  .l-container-subnav > .c-sns-share {
    width: 100%;
    float: none;
    margin-bottom: 40px;
    text-align: center;
  }
  .l-section .c-table.c-table-companysummary {
    width: 100%;
  }
  .c-partnership-summary-list {
    width: 80%;
    margin: 0 auto 40px auto;
    padding: 0;
  }
  .c-annouce {
    width: 100%;
    > dd {
      margin: 0;
    }
  }
  /* form */
  .l-section .c-table.f-form-entry {
    display: block;
    width: 100%;
    tbody, thead, tr, tr > th, tr > td {
      display: block;
      box-sizing: border-box;
    }
    tr > th, tr > td {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    th + td {
      padding-top: 0;
    }
  }
  .l-section .c-table.c-table-cnt-confirm,
  .l-section .c-table.c-table-rsv-confirm {
    tr > th {
      text-align: left;
    }
  }
  .f-input-small,
  .f-input-medium,
  .f-input-large,
  .f-textarea-large,
  .f-textarea-xlarge {
    box-sizing: border-box;
  }
  .f-input-medium,
  .f-input-large,
  .f-textarea-large {
    width: 100%;
  }
  /* reservation form */
  .c-rsv-chooses {
    width: 100%;
    > li {
      display: inline-block;
      float: none;
      margin-bottom: 24px;
      border-radius: 5px;
      > select {
        width: 70%;
      }
      > select[name="rsv_store"] {
        width: 300px;
      }
      > select[name="rsv_sheet"] {
        width: 240px;
      }
      // pickatime library
      .picker--time .picker__box {
        font-size: 1.2em;
      }
    }
    > li + li {
      border-left: solid 1px #919191;
    }
  }
  .c-rsv-chooses.c-rsv-chooses-shop {
    width: 100%;
  }
}
